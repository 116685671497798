import { graphql } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import * as React from 'react'
import Gallery from '../../../gatsby-image-gallery/src'
import Layout from '../components/layout'
import SEO from '../components/seo'

interface ImageSharpEdge {
  node: {
    childImageSharp: {
      thumb: IGatsbyImageData
      full: IGatsbyImageData
    }
  }
}

interface PageProps {
  data: {
    images: {
      edges: ImageSharpEdge[]
    }
  }
}

const IndexPage: React.FC<PageProps> = ({ data }) => {
  const [hasLoaded, setHasLoaded] = React.useState(false)
  const images = data.images.edges.map(({ node }) => ({
    ...node.childImageSharp,
    // Use original name as caption.
    // The `originalName` is queried in a nested field,
    // but the `Gallery` component expects `caption` at the top level.
    caption: '',
  }))

  React.useEffect(() => {
    setHasLoaded(true)
  })

  for (let x = 0; x < images.length; x++) {
    images[x].caption = String(x + 1).padStart(4, '0')
  }

  const lightboxOptions = {
    imageLoadErrorMessage: 'Error while loading image',
    nextLabel: 'Next Image',
    prevLabel: 'Previous Image',
    zoomInLabel: 'Zoom in',
    zoomOutLabel: 'Zoom out',
    closeLabel: 'Close',
  }

  //Add callback to Lightbox onCloseRequest
  const onClose = () => {
    console.log('Lightbox was closed')
  }

  return (
    <Layout>
      <SEO title="Infinity Labs Gallery" />
      <h1>Infinity Labs: Scenes of the Solverse</h1>
      <p>
        Below is a gallery of all 1111 unique scenes of the solverse NFTs by
        Infinity Labs, exclusively on the Solana blockchain. Scenes and their
        pre-merge timelines are available to purchase, available on{' '}
        <a href="https://solanart.io/collections/infinitylabs">Solanart</a>
        {', '} <a href="https://alpha.art/collection/infinitylabs">Alpha Art</a>
        {', '}{' '}
        <a href="https://ftx.us/nfts/collection/Infinity%20Labs/25/1">FTX</a>{' '}
        secondary markets.
      </p>

      {hasLoaded ? (
        <Gallery
          images={images}
          lightboxOptions={lightboxOptions}
          onClose={onClose}
          startIndex={0}
        />
      ) : (
        <></>
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  query ImagesForGallery {
    images: allFile(
      filter: { relativeDirectory: { eq: "gallery" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 270
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`

export default IndexPage
